jQuery( function( $ ) {

	$( '.js-topMvScroll' ).on( 'click', function(){
		var position = $( '#l-main' ).offset().top;
		$( 'body, html' ).animate( { scrollTop: position }, 400, 'swing' );
		return false;
	} );

	//font
	var userAgent = window.navigator.userAgent.toLowerCase();
	if ( ( userAgent.indexOf( 'trident' ) != -1 ) || ( userAgent.indexOf( 'edge' ) != -1 ) ) {
		$( 'body' ).css( "font-family", '"メイリオ", "Meiryo"' );
	} else {
		$( 'body' ).css( "font-family", '"Noto Sans Japanese"' );
	}

	var matchHeight = require( './jquery.matchHeight.js' );
	var matchHeightConfig = require( './matchHeightConfig.js' );
	var button = require( './button.js' );
	var simplemap = require( './simplemap.js' );
	var loading = require( './loading.js' );
});
