jQuery( function( $ ) {

	$( window ).on('load resize', function() {
		var wpadminbar_h = $( '#wpadminbar' ).height();
		var windowWidth = $( window ).width();

		if( wpadminbar_h ) {
			$( '.p-gnavi__menu' ).css( {
				'top': 100 + wpadminbar_h
			} );
		}
		$( '.is-smtnavi--active' ).each( function() {
			$( 'html .is-smtnavi--active' ).removeClass( 'is-smtnavi--active' );
		} );
	} );

	$( '.c-button--hamburger' ).click( function() {
		$( this ).toggleClass( 'is-smtnavi--active' );
		$( '.p-gnavi__menu' ).toggleClass( 'is-smtnavi--active' );
		$( 'body' ).toggleClass( 'is-smtnavi--active' );
	} );

	$( '.c-button--subnavi' ).click( function() {
		$( this ).toggleClass( 'is-smtnavi--active' );
		$( this ).parent().siblings( '.p-subnavi__menu' ).toggleClass( 'is-smtnavi--active' );
	} );

});
