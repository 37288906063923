jQuery( function( $, window ) {
	var i, imgH, imgW;
	var tabletW = 641;

	$( window ).on('load resize', function() {
		imgH = $( '.p-content__set--map img' ).height();
		if( $( window ).width() < tabletW ) {
			$( '.p-simplemap .p-content__set--map' ).each( function( i ) {
				$( '.p-content__set--map .simplemap-content' ).css( 'height', imgH );
			} );
			$( '.u-loading--map' ).css( 'margin-top', imgH / 2 );
			$( '.u-loading--map__wrap' ).css( 'padding-top', imgH );
			$( '.u-loading--map__item' ).css( 'height', imgH );
		}
		if( $( window ).width() > tabletW ) {
			$( '.u-loading--map' ).css( 'margin-top', 0 );
			$( '.u-loading--map__wrap' ).css( {
				'padding-top': 0,
				'height': imgH
			});
			$( '.p-simplemap .p-content__set--map' ).each( function( i ) {
				$( '.p-content__set--map .simplemap-content' ).css( 'height', imgH );
			} );
			$( '.u-loading--map__item' ).css( 'height', imgH );
		}
	} );
});
